<template>
    <div>
        <span>{{ $t('residencesList.display') }}</span>
        <i class="fas fa-bars mobile" :class="{ activeDisplayMode: this.$store.state.account.displayKeyCard == 'list' }"
            @click=" display('list')"></i>
        <i class="fas fa-th-large" :class="{ activeDisplayMode: this.$store.state.account.displayKeyCard == 'card' }"
            @click="display('card')"></i>
    </div>
</template>

<script>
export default {
    name: 'displayModeKeyCard',
    props: {
        displayKeys: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            displayKeyCard: '',
        }
    },
    methods: {
        display(mode) {
            this.displayKeyCard = mode
            this.$store.commit('account/setDisplayKeyCard', this.displayKeyCard)
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

div {
    display: flex;

    gap: 1em;
    height: max-content;

    span {
        color: $grey-darker;
        border-radius: 4px;
        transition: all 0.3s ease-in-out;
        display: flex;
        align-items: center;
        padding: 0 1em;
        font-family: $font_avenir_heavy;
    }

    i {
        color: $grey-darker;
        cursor: pointer;
        padding: 8px;
        border-radius: 4px;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: $grey-neutral;
            color: $orange-light;
            transition: all 0.3s ease-in-out;
        }
    }

    .activeDisplayMode {
        background-color: $grey-dark;
        color: $white;
    }

    .activeDisplayKey {
        background-color: $grey-dark;
        color: $white;
    }
}
</style>